<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="login_page">
            <div class="logo_area">
              <img src="@/assets/static/images/logo.svg" alt="모두 함께해" />
            </div>
            <div class="form_wrap">
              <div class="form">
                <ul class="form_ul">
                  <li class="form_li form_li_12">
                    <div class="form_in">
                      <div class="inline">
                        <label class="input">
                          <input
                            type="text"
                            placeholder="아이디"
                            v-model.trim="userId"
                            @keyup.enter="fnSignIn"
                          />
                        </label>
                      </div>
                      <div class="hint" :class="{ on: valid.userId.$error }">
                        {{ msg.userId }}
                      </div>
                    </div>
                  </li>
                  <li class="form_li form_li_12">
                    <div class="form_in">
                      <div class="inline">
                        <label class="input">
                          <input
                            type="password"
                            placeholder="비밀번호"
                            v-model="userPw"
                            @keyup.enter="fnSignIn"
                          />
                        </label>
                      </div>
                      <div class="hint" :class="{ on: valid.userPw.$error }">
                        {{ msg.userPw }}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="con_btn">
              <div class="btn_wrap30">
                <button class="m_btn" @click="fnSignIn">로그인</button>
              </div>
            </div>
            <div class="con_btn">
              <div class="btn_wrap two_type">
                <button class="text_btn" @click="fnPage(1)">
                  아이디/비밀번호를 잊으셨나요?&nbsp;
                </button>
                <button class="text_btn" @click="fnPage(2)">회원가입</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { reactive, getCurrentInstance, toRefs } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const loginState = () => {
  /** init(vue 전역설정 import) */
  const { proxy } = getCurrentInstance()
  const router = useRouter()
  const { getters } = useStore()

  /** data */
  const state = reactive({
    userId: '',
    userPw: '',
    msg: {
      userId: '',
      userPw: ''
    }
  })

  /** validations **/
  const rules = {
    userId: {
      test: function(val) {
        if (proxy.$Util.isEmpty(val)) {
          state.msg.userId = '아이디를 입력해 주세요.'
          return false
        }
        if (val.length < 6) {
          state.msg.userId = '아이디는 6자 이상 입력해야 합니다.'
          return false
        }
        return true
      }
    },
    userPw: {
      test: function(val) {
        if (proxy.$Util.isEmpty(val)) {
          state.msg.userPw = '비밀번호를 입력해 주세요.'
          return false
        }
        if (val.length < 4 || val.length > 11) {
          state.msg.userPw =
            '비밀번호는 4자리 이상 12자리 이하 입력해야 합니다.'
          return false
        }
        return true
      }
    }
  }

  const valid = useVuelidate(rules, state)

  /** methods */
  const fnSignIn = async () => {
    valid.value.$touch()
    if (valid.value.$invalid) return

    const res = await proxy.$SignSvc.login(state)
    if (res.code === 1) {
      const deviceInfo = getters['user/getDeviceInfo']
      if (proxy.$Util.isApp() && !proxy.$Util.isEmpty(deviceInfo)) {
        await proxy.$UserSvc.insertUserDevice(deviceInfo)
      }
      await router.replace({ path: `/main`, query: { isRoot: 1 } })
    } else if (res.code === -1000) {
      alert(res.message)
    } else if (res.code === -1001) {
      alert(res.message)
    } else if (res.code === -1002) {
      alert(res.message)
    } else if (res.code === -1003) {
      alert(res.message)
    } else if (res.code === -1007) {
      alert(res.message)
    }
  }
  const fnPage = page => {
    if (page === 1) {
      router.push({ path: '/find' })
    } else {
      router.push({
        path: '/sign/up'
      })
    }
  }
  return { valid, fnSignIn, ...toRefs(state), fnPage }
}

export default {
  name: 'sign-in-index',
  description: '로그인 페이지',
  setup() {
    return {
      ...loginState()
    }
  }
}
</script>

<style scoped></style>
